body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}


::-webkit-scrollbar{width:8px;height:8px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:grey;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#231f20}.header{display:flex;height:32px;padding:8px;width:100%;background-color:#fff}.timer-details{display:flex;margin-left:auto}.timer-items{padding:8px}.toggle-header{padding:8px;height:32px;width:32px}.sidenav-toggle-icon{height:32px;width:32px}.product-image{height:42px}.user{width:15%;text-align:end;display:flex}.user-details{display:flex;align-items:flex-end;align-content:flex-end;margin-left:auto;margin-right:32px}.user-icon{width:32px;height:32px;font-size:24px;margin-top:5px}.name-role{font-size:10px;text-align:end;height:32px}.toggle-side-image{height:32px}.link{background-color:Transparent;text-decoration:none;color:white}.link-conatiner{padding:8px}.MuiAppBar-colorPrimary{background-color:#003333 !important}.menu .MuiPaper-root{margin:0 !important}.menu .MuiAccordion-root{background-color:#003333}.menu .MuiList-padding{padding:0}.menu .MuiList-root{width:260px}.menu .MuiAccordionDetails-root{padding-top:0px;padding-bottom:0px;padding-left:50px}.menu .MuiListItem-root{padding:0px;padding-bottom:8px}.menu .MuiListItemIcon-root{min-width:38px}.menu .MuiSvgIcon-root{fill:white}.menu-link{background-color:Transparent;text-decoration:none;color:black;padding:4px;width:100%;height:100%}.menu-text-color{color:white;font-size:14px}

.swagger-card{padding:16px}.display-flex{display:flex}.launch{margin-left:35%;padding-bottom:25px}

::-webkit-scrollbar{width:8px;height:8px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:grey;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#231f20}.message{position:absolute;width:180px;height:30px;top:50%;left:50%;font-size:18px}.spinner-image{width:40px;height:40px}.image-container{padding-left:40%}

.training-div{display:flex;flex-wrap:wrap}.link-list{color:black !important}

.subpoints{padding-left:16px}.flex{display:flex}.padding{padding-right:24px}

::-webkit-scrollbar{width:8px;height:8px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:grey;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#231f20}.nuget-container{display:flex}.dependency-list{width:100%}.populate-button{padding:8px}.result{display:flex}.nuget-label{height:30px;margin-left:40px}.MuiList-padding{padding-top:0px !important;padding-bottom:0px !important}.nuget-header{padding:8px;margin-left:20%}.data-list{display:flex;padding:16px}.direct{min-height:100px}.indirect{min-height:100px}.all-list{padding-top:10px}.items{height:700px;width:500px;overflow:auto}

::-webkit-scrollbar{width:8px;height:8px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:grey;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#231f20}.construction-message{position:absolute;width:150px;height:30px;top:15%;left:20%}.construction-image{width:1000px}

.feedload-timeline .MuiTimelineDot-defaultGrey{background:none}.one-line{display:flex}.paper-padding{padding-left:8px;padding-right:8px}.activity-name{padding:8px;color:black;font-size:18}.header-button{padding:8px;font-size:20px}

.card{padding:20px;min-width:250px;max-width:300px;min-height:300px;max-height:430px;flex-direction:column;justify-content:center;display:flex}.text{align-self:center;max-height:120px}.media{align-self:center;min-width:170px;min-height:150px}.display{padding:20px}

.flex-container{display:flex;flex-wrap:wrap}

#yammer{border:0px;overflow:hidden;width:100%;height:100%;min-height:82vh}

.root{width:"100%"}.table{min-width:800px}.tableWrapper{overflow-x:auto}.tableCell{font-weight:900px}.flex-container{padding:20px;display:flex;flex-direction:row}.text{padding-left:30px}

.video-display{display:flex;flex-wrap:wrap}.display{display:flex;flex-direction:column}

.iframe{padding:15px}.iframe-name{justify-content:center;align-content:center;display:flex}


.component-container,.component-header-wrapper{overflow:hidden}.horizontal-nav-bar-placeholder{overflow:hidden}.component-placeholder{padding:8px;overflow:hidden}

::-webkit-scrollbar{width:8px;height:8px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:grey;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#231f20}.backImage{width:100%;height:650px;filter:blur(10px);-webkit-filter:blur(10px)}*{box-sizing:border-box}.bg-text{background-color:#000;background-color:rgba(0,0,0,0.85);color:white;font-weight:bold;border:3px solid #f1f1f1;position:absolute;top:50%;left:60%;transform:translate(-50%, -50%);z-index:2;width:50%;padding:30px;text-align:center}.dashboard-message{position:absolute;width:500px;height:30px;top:20%;text-align:center;left:35%;font-size:large;z-index:99;background-color:white}.assistant{width:200px;height:200px}.dashboard{width:100%;height:100%}

