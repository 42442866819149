$standard-padding: 8px;
$image-dimension: 32px;
$nav-image-container-width: $image-dimension + 2 * $standard-padding;

.component-container,
.component-header-wrapper {
  overflow: hidden;
}

.horizontal-nav-bar-placeholder {
  overflow: hidden;
  //padding: 0 8px;
}

.component-placeholder {
  padding:  $standard-padding;
  overflow: hidden;
}
