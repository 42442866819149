$standard-image-dimension-16: 16px;
$standard-image-dimension-24: 24px;
$standard-image-dimension-32: 32px;
$standard-image-dimension-64: 64px;

//colors :
$white: white;
$grey: grey;
$light-grey: #757575; //rgb(245, 245, 245);
$blue: rgb(0, 120, 212);
$dark-grey: #231f20;
$green: rgb(74, 204, 14);
$mint-green: #74ab43;
$disabled-text: rgb(200, 198, 196);

$image-dimension: $standard-image-dimension-32;
$standard-padding: 8px;
$text-color: white;
$standard-font-size: 10px;
$standard-icon-font-size: 24px;
$standard-border-radius: 10px;

///****************ScrollBar************************************8

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-grey;
}
